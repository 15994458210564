// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { lazy, createContext, useContext, useMemo, useCallback, useState, Suspense } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import PropTypes from 'prop-types';

const ModulesContext = createContext({});

const loadScope = (url, scope) => {
    if (window[scope]) {
        return window[scope];
    }
    const element = document.createElement('script');
    const promise = new Promise((resolve, reject) => {
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
        element.onload = () => resolve(window[scope]);
        element.onerror = reject;
    });
    document.head.appendChild(element);
    promise.finally(() => document.head.removeChild(element));
    return promise;
};

const loadModule = async (url, scope, module) => {
    try {
        const container = await loadScope(url, scope);
        await __webpack_init_sharing__('default');
        // eslint-disable-next-line camelcase
        await container.init(__webpack_share_scopes__.default);
        const factory = await container.get(module);
        return factory();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading module:', error);
        return { default: () => null };
    }
};

const useDynamicModule = () => useContext(ModulesContext);

const ModuleProvider = ({ children }) => {
    const [modules, setModules] = useState({});
    const [visible, setVisible] = useState(null);

    const openModule = useCallback((moduleString, props) => {
        const { scope, entry, url } = /module:(?<scope>.+):(?<entry>.+)@(?<url>.+)/.exec(moduleString)?.groups ?? {};

        if (!(scope && url && entry)) {
            return;
        }
        if (!modules[scope]) {
            setModules(prev => ({
                ...prev,
                [scope]: lazy(() => loadModule(url, scope, `./${entry}`)),
            }));
        }
        if (!visible || (visible.scope !== scope && JSON.stringify(visible.props) !== JSON.stringify(props))) {
            setVisible({ name: scope, props });
        }
    }, [visible, modules]);

    const module = useMemo(() => {
        if (!visible) {
            return null;
        }
        const { name, props } = visible;
        return {
            Component: modules[name],
            props,
            onClose: () => setVisible(null),
        };
    }, [modules, visible]);

    const values = useMemo(() => ({
        module,
        openModule,
    }), [module, openModule]);

    return (<ModulesContext.Provider value={values}>{children}</ModulesContext.Provider>);
};

ModuleProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const DynamicModule = () => {
    const { module } = useDynamicModule();
    if (!module) {
        return null;
    }

    const { Component, props, onClose } = module;
    return (
        <Suspense fallback={<TopBarProgress />} >
            <Component {...props} onClose={onClose} />
        </Suspense>
    );
};

export { ModuleProvider, DynamicModule, useDynamicModule };
