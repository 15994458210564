// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const isNetworkError = error => {
    const errors = [
        // chrome
        'Failed to fetch',
        // firefox
        'NetworkError when attempting to fetch resource.',
        // safari
        'Fetch API cannot load',
        'Network request failed',
    ];

    return errors.some(e => error.message.startsWith(e));
};
