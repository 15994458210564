// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { PageHeader, Breadcrumbs, Heading, Text } from '@plesk/ui-library';
import Link from 'components/common/Link';
import ObjectSwitcher from './ObjectSwitcher';
import PageContentHeaderNav from './PageContentHeaderNav';
import stripTags from 'helpers/stripTags';
import JswComponent from 'components/jsw/JswComponent';

const PageContentHeader = ({
    breadcrumbs,
    tabs,
    tabsHasRootPage,
    navigationTabs,
    hidePathbar,
    hideHeading,
    wizardTitle,
    pageTitle,
    pageSubtitle,
    titleRightAddon,
    objectSwitcherParams,
    forceObjectSwitcher,
    isClassicMode,
    additionalTitleContent,
}) => {
    breadcrumbs = Array.isArray(breadcrumbs) ? [...breadcrumbs] : [];
    let currentPage = breadcrumbs.pop();
    let addObjectSwitcher = forceObjectSwitcher;
    let objectName = null;
    let pageTitleSuffix = null;

    if (currentPage && tabs && tabsHasRootPage) {
        currentPage = breadcrumbs.pop();
        if (!pageTitle) {
            pageTitle = currentPage.title;
        }
    }

    if (currentPage && navigationTabs) {
        currentPage = breadcrumbs.pop();
        pageTitle = currentPage.title;

        // subscription horizontal navigation
        addObjectSwitcher = pageTitle.indexOf('</b>') !== -1;
    }

    const match = pageTitle && pageTitle.match(/<b>(.+)<\/b>/i);
    if (match) {
        objectName = match[1];
        pageTitleSuffix = pageTitle.substr(match.index + match[0].length);
        pageTitle = pageTitle.substr(0, match.index);

        const previousPage = breadcrumbs[breadcrumbs.length - 1];
        if (previousPage && objectName === stripTags(previousPage.title)) {
            objectName = (
                <Link to={previousPage.href} dangerouslySetInnerHTML={{ __html: stripTags(previousPage.title) }} />);
            breadcrumbs.pop();

            // domain horizontal navigation
            addObjectSwitcher = true;
        } else {
            objectName = (
                // TODO EXTSSLIT-590: EXTPHPCOMP-33: Broken header in Plesk 18.0.19
                // eslint-disable-next-line react/no-danger
                <span className="page-content-header__title" dangerouslySetInnerHTML={{ __html: objectName }} />
            );
        }
    }

    if ((hidePathbar || breadcrumbs.length) && hideHeading && (!isClassicMode || !navigationTabs)) {
        return null;
    }

    return (
        <PageHeader
            className="page-content-header"
            breadcrumbs={!hidePathbar && breadcrumbs.length ? (
                <Breadcrumbs data-type="page-pathbar">
                    {breadcrumbs.map(({ title, href }) => (
                        <Link key={href} to={href} dangerouslySetInnerHTML={{ __html: stripTags(title) }} />
                    ))}
                </Breadcrumbs>
            ) : null}
            tabs={isClassicMode && navigationTabs ? (
                <PageContentHeaderNav items={navigationTabs} />
            ) : null}
            title={hideHeading ? null : (
                <div>
                    {wizardTitle ? (
                        <Heading>{wizardTitle}</Heading>
                    ) : null}
                    {objectName ? (
                        <Heading level={wizardTitle ? 3 : 1} data-type="page-title">
                            {pageTitle ? (
                                // TODO EXTLETSENC-726: EXTSSLIT-590: EXTPHPCOMP-33: Broken header in Plesk 18.0.19
                                // eslint-disable-next-line react/no-danger
                                <span className="page-content-header__title" dangerouslySetInnerHTML={{ __html: pageTitle }} />
                            ) : null}
                            {objectName}
                            {objectSwitcherParams && addObjectSwitcher ? (
                                <ObjectSwitcher {...objectSwitcherParams} />
                            ) : null}
                            {pageTitleSuffix}
                            {additionalTitleContent && (<JswComponent component="span">{additionalTitleContent}</JswComponent>)}
                        </Heading>
                    ) : (
                        <Heading level={wizardTitle ? 3 : 1} data-type="page-title">
                            {/* eslint-disable-next-line react/no-danger */}
                            <span className="page-content-header__title" dangerouslySetInnerHTML={{ __html: pageTitle }} />
                        </Heading>
                    )}
                </div>
            )}
            titleRightAddon={titleRightAddon}
        >
            {!hideHeading && pageSubtitle ? (
                <Text intent="muted">{pageSubtitle}</Text>
            ) : null}
        </PageHeader>
    );
};

PageContentHeader.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
    })),
    tabs: PropTypes.array,
    tabsHasRootPage: PropTypes.bool,
    navigationTabs: PropTypes.array,
    hidePathbar: PropTypes.bool,
    hideHeading: PropTypes.bool,
    wizardTitle: PropTypes.string,
    pageTitle: PropTypes.string,
    pageSubtitle: PropTypes.string,
    titleRightAddon: PropTypes.node,
    objectSwitcherParams: PropTypes.shape(ObjectSwitcher.propTypes),
    forceObjectSwitcher: PropTypes.bool,
    isClassicMode: PropTypes.bool,
    additionalTitleContent: PropTypes.string,
};

PageContentHeader.defaultProps = {
    breadcrumbs: [],
    tabs: null,
    tabsHasRootPage: false,
    navigationTabs: null,
    hidePathbar: false,
    hideHeading: false,
    wizardTitle: null,
    pageTitle: null,
    pageSubtitle: null,
    titleRightAddon: null,
    objectSwitcherParams: null,
    forceObjectSwitcher: false,
    isClassicMode: false,
    additionalTitleContent: null,
};

export default PageContentHeader;
