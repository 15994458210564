// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

/* eslint-disable react/no-multi-comp */

import { Container } from './container';
import { Component, getComponent } from './component';
import { Button } from './button';
import render from './render';
import escapeHtml from './escapeHtml';
import { createHtmlElement } from '../helpers/createHtmlElement';

import './bar.less';

export class Bar extends Container {
    /**
     * @cfg {String} type=static Type of tabs (static or dynamic)
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._contentAreaId = `${this._id}-content-area`;
        this._type = this._getConfigParam('type', 'static');
    }

    _initComponentElement() {
        super._initComponentElement();

        let content = `<ul id="${this._contentAreaId}"></ul>`;
        if ('navbar' !== this._id) {
            content = `<div class="tabs-area">${content}</div>`;
        }

        this._updateComponentElement(content);
    }

    _renderItem(item) {
        const renderTargetId = `${this._id}-item-${item.getId()}`;
        const containerCls = item.getContainerClass();

        render(document.getElementById(this._contentAreaId), `<li class="${containerCls}" id="${renderTargetId}"></li>`);
        render(document.getElementById(renderTargetId), item);

        if ('dynamic' === this._type) {
            item.addEventObserver('click', event => item.switchTab(event));
        }

        if (document.getElementById('main')) {
            if (!document.getElementById('main').classList.contains('tabed') && 'navbar' !== this._id) {
                document.getElementById('main').classList.add('tabed');
            }
        }
    }
}

/**
 * The following example demonstrates how to use static tabs:
 *
 *     @example
 *     new bar.Tabs({
 *         renderTo: document.body,
 *         cls: 'tabs',
 *         items: [{
 *             componentType: bar.Button,
 *             title: 'Tab 1',
 *             active: true,
 *             href: 'http://example1.com'
 *         }, {
 *             componentType: bar.Button,
 *             title: 'Tab 2',
 *             href: 'http://example2.com'
 *         }],
 *     });
 *
 * The following example demonstrates how to use dynamic tabs:
 *
 *     @example
 *     new bar.Tabs({
 *         renderTo: document.body,
 *         cls: 'tabs',
 *         type: 'dynamic',
 *         items: [{
 *             componentType: bar.Button,
 *             tabId: 'tab-1',
 *             title: 'Tab 1',
 *             active: true,
 *             content: 'Content of Tab 1'
 *         }, {
 *             componentType: bar.Button,
 *             tabId: 'tab-2',
 *             title: 'Tab 2',
 *             content: new Box({
 *                 html: 'Content of Tab 2'
 *             }),
 *         }],
 *     });
 *
 */
export const bar = {};
bar.Tabs = class extends Bar {
    /**
     * @cfg {Boolean} responsive=true
     */

    _initConfiguration(config) {
        super._initConfiguration(config);
        this._isResponsive = this._getConfigParam('responsive', true);
    }

    _renderItems() {
        super._renderItems();

        if (this._isResponsive) {
            this._addResponsiveTabs();
        }
    }

    _addResponsiveTabs() {
        const element = document.getElementById(this._id);
        element.querySelectorAll('li a').forEach(el => {
            el.addEventListener('click', event => {
                const listItem = el.closest('li');
                if (listItem.classList.contains('active') || listItem.id === 'current') {
                    event.preventDefault();
                }
                element.classList.toggle('responsive-tabs-visible');
            });
        });
    }

    switchTab(tabId) {
        let activeTab = null;

        this.getItems().forEach(function (item) {
            if (tabId === item.getTabId()) {
                activeTab = item;
            }

            document.getElementById(item.getTabId()).style.display = 'none';
            item.getRenderTarget().classList.remove('active');
        });

        document.getElementById(tabId).style.display = '';
        activeTab.getRenderTarget().classList.add('active');
    }

    _renderItem(item, i, size) {
        super._renderItem(item, i, size);

        const content = item.getTabContent();

        if (content) {
            const tabContentElement = createHtmlElement('div', {
                id: item.getTabId(),
                style: !item.isActive() ? 'display: none' : '',
            });

            render(this._componentElement.parentNode, tabContentElement);
            render(tabContentElement, content, typeof content === 'string' && 'inner');
        }
    }
};

bar.Button = class extends Button {
    /**
     * @cfg {Boolean} active=false
     */
    /**
     * @cfg {String} containerCls
     */
    /**
     * @cfg {String} tabId
     */

    /**
     * @cfg {Boolean} navigationTab=false
     */
    /**
     * @cfg {String|Component} content
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._isActive = this._getConfigParam('active', false);
        this._cls = this._getConfigParam('cls', '');
        this._containerCls = this._getConfigParam('containerCls', this._isActive ? 'active' : '');
        this._tabId = this._getConfigParam('tabId', null);
        if (this._tabId) {
            this._id = `${this._tabId}-button`;
        }
        this.navigationTab = this._getConfigParam('navigationTab', false);
        this._content = this._getConfigParam('content', null);
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.innerHTML = `<span>${escapeHtml(this._title)}</span>`;
    }

    getContainerClass() {
        return this._containerCls;
    }

    getTabId() {
        return this._tabId;
    }

    switchTab(event) {
        const bar = getComponent(event.target.closest('ul').parentNode
            .parentNode.id);
        bar.switchTab(this._tabId);
        event.preventDefault();
    }

    getTabContent() {
        return this._content;
    }

    isActive() {
        return this._isActive;
    }
};

bar.Separator = class Separator extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'span',
            cls: 'separator',
            ...config,
        });
    }

    _initComponentElement() {
        super._initComponentElement();
        this._componentElement.innerHTML = '<span></span>';
    }
};

bar.HorizontalSeparator = class HorizontalSeparator extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'span',
            wrapperClass: 'separator',
            ...config,
        });
    }
};
