// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { gql } from '@apollo/client';
import apolloClient from 'apolloClient';
import { getId } from 'helpers/getId';

import ASP_DOT_NET_DOMAIN_SETTINGS_PAGE from 'queries/AspDotNetDomainSettingsPage.graphql';

export const aspDotNetDomainSettingsRoute = {
    path: 'asp-dot-net-settings',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage" */'components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage'),
    loader: async ({ request, params }) => {
        const url = new URL(request.url);
        const webPath = url.searchParams.get('webPath') || '';
        const { data } = await apolloClient.query({
            query: ASP_DOT_NET_DOMAIN_SETTINGS_PAGE,
            variables: { id: btoa(`Domain:${params.domainId}`), webPath },
        });
        return data;
    },
    isExist: async ({ params }) => {
        const { data: { domain: { webScriptingSettings, aspNetSettings } } } = await apolloClient.query({
            query: gql`
                query($id: ID!) {
                    domain: node(id: $id) {
                        ... on Domain {
                            webScriptingSettings {
                                aspDotNet
                            }
                            aspNetSettings {
                                configs {
                                    version
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: btoa(`Domain:${params.domainId}`),
            },
        });

        return webScriptingSettings !== null && webScriptingSettings.aspDotNet !== null && aspNetSettings.configs.length;
    },
    handle: {
        helpContext: 'aspdotnet',
        crumb: ({ data, matchesContext, params, location }) => {
            const { domain } = matchesContext.domainRouteData;
            const searchParams = new URLSearchParams(location.search);
            const webPath = searchParams.get('webPath') || '';
            const clientId = getId(data.domain.owner.id);

            const locale = Locale.getSection('smb.controllers.asp-dot-net.settings');
            const routesLocale = Locale.getSection('components.routes');

            const crumbs = webPath
                ? [
                    {
                        title: routesLocale.lmsg('virtualDirectories'),
                        href: `/plesk/client@${clientId}/domain@${params.domainId}/hosting/web-directories/`,
                    },
                    {
                        title: locale.lmsg('vdirTitle', { vdir: webPath }),
                        href: `/cp/domains/${params.domainId}/asp-dot-net-settings?webPath=${webPath}}`,
                    },
                ] : [
                    {
                        title: locale.lmsg('domainTitle', { domain: `<b>${domain.name}</b>` }),
                        href: `/cp/domains/${params.domainId}/asp-dot-net-settings`,
                    },
                ];

            return crumbs;
        },
    },
};
