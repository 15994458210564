// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */
import { addToast } from 'helpers/toasts';

import { Component } from './component';
import { Container } from './container';
import { redirect } from './form-redirect';
import Observer from './Observer';
import createComponent from './createComponent';
import prepareUrl from './prepareUrl';
import escapeHtml from './escapeHtml';
import api from './api';
import pleskWS from './pleskWS';
import Locale from './Locale';

const STATUS_DONE = 'done';
const STATUS_ERROR = 'error';
const STATUS_STARTED = 'started';
const STATUS_NOT_STARTED = 'not_started';
const STATUS_PREPARING = 'preparing';
const STATUS_FLYING = 'flying';
const STATUS_CANCELED = 'canceled';

class Pool {
    constructor(items) {
        this.items = items;
        this.map = items.reduce((acc, item) => {
            acc[item.getId()] = item;
            return acc;
        }, {});
    }

    take(id) {
        if (this.map[id]) {
            const item = this.map[id];
            delete this.map[id];
            this.items = this.items.filter(item => item.getId() !== id);
            return item;
        }
    }

    shift() {
        const item = this.items.shift();
        if (item) {
            delete this.map[item.getId()];
            return item;
        }
    }

    hasItems() {
        return this.items.length > 0;
    }
}

const generateUniq = () => Math.random().toString(36).slice(2);

export class ProgressBar extends Container {
    _initConfiguration(config) {
        super._initConfiguration({
            id: 'asyncProgressBarWrapper',
            ...config,
        });

        this._preparingCounter = 0;
        this._hidden = false;

        this.intervalUpdateTimer = null;
        this.pleskWS = null;
        if (this._getConfigParam('wsEnabled')) {
            this.pleskWS = pleskWS.bind({
                actions: {
                    // eslint-disable-next-line camelcase
                    task_created: this.onUpdated.bind(this),
                    // eslint-disable-next-line camelcase
                    task_updated: this.onUpdated.bind(this),
                    // eslint-disable-next-line camelcase
                    task_deleted: this.onDeleted.bind(this),
                },
                onOpen: this.loadTasks.bind(this),
                onClose: this.loadTasks.bind(this),
            });
        } else {
            this.loadTasks();
        }
    }

    addPreparingItem(title) {
        const id = `preparing-${this._preparingCounter}`;
        const item = new ProgressBar.Item({
            errors: [],
            progressTitle: title,
            status: 'flying',
            id,
            uniq: generateUniq(),
        });
        this._preparingCounter++;
        this._items.unshift(item);
        return id;
    }

    // do not remove due to backward compatibility
    removePreparingItem() {
    }

    // do not remove due to backward compatibility
    toggle() {
    }

    async loadTasks() {
        try {
            const { items } = await api.get(prepareUrl('/task/task-progress'));

            if (Array.isArray(items)) {
                this._loaded = true;
                this.mergeItems(items.map(createComponent));
            }
        } catch {}
    }

    mergeItems(items) {
        let nextItems = this.getMergedItems(items);

        // remove completed items
        this.getItems().forEach(item => {
            if (!items.some(newItemData => item.getId() === newItemData.getId())) {
                this.onItemStatusChange(this.completeItem(item.initialConfig));
                nextItems = nextItems.filter(currentItem => currentItem.getId() !== item.getId());
            }
        });

        this.setItems(nextItems);
    }

    mergeItem(item) {
        const nextItems = this.getMergedItems([item]);
        this.setItems(nextItems);
    }

    getMergedItems(items) {
        const pool = new Pool(items);
        let nextItems = this.getItems();
        // update exist items
        nextItems = nextItems.map(item => {
            const nextItem = pool.take(item.getId());
            if (nextItem) {
                if (item.getUpdated() > nextItem.getUpdated()) {
                    return item;
                }
                if (nextItem.getStatus() !== item.getStatus()) {
                    this.onItemStatusChange(nextItem.getData());
                } else if (JSON.stringify(nextItem.getSteps()) !== JSON.stringify(item.getSteps())) {
                    this.onItemStepChange(nextItem.getData());
                }
                nextItem.setRefreshLinkEnabled(STATUS_STARTED !== nextItem.getStatus() && item.isStarted() ? true : item.isRefreshLinkEnabled());
                nextItem.setUniq(item.getUniq());
                return nextItem;
            }
            return item;
        });

        // replace preparing items by new items
        if (pool.hasItems()) {
            nextItems = nextItems.map(item => {
                if (item.isFlying()) {
                    const nextItem = pool.shift();
                    if (nextItem) {
                        this.onItemStatusChange(nextItem.getData());
                        nextItem.setUniq(item.getUniq());
                        return nextItem;
                    }
                }
                return item;
            });
        }

        // add new items
        if (pool.hasItems()) {
            let nextItem;
            do {
                nextItem = pool.shift();
                if (nextItem) {
                    this.onItemStatusChange(nextItem.getData());
                    nextItems.unshift(nextItem);
                }
            } while (nextItem);
        }

        return nextItems;
    }

    // do not remove due to backward compatibility
    update() {
        if (this.pleskWS && this.pleskWS.isReady()) {
            this.stopPeriodicalExecutor();
            return;
        }

        this.loadTasks();
    }

    onUpdated(data) {
        this.mergeItem(createComponent(data));
    }

    onDeleted(data) {
        this.onItemStatusChange(this.completeItem(data));
        this.removeItemsByIds([data.id]);
    }

    removeItemsByIds(ids) {
        if (!ids.length) {
            return;
        }
        const items = this.getItems();
        for (let i = 0; i < ids.length; i++) {
            for (let j = 0; j < items.length; j++) {
                if (items[j].getId() === ids[i]) {
                    items.splice(j, 1);
                    break;
                }
            }
        }
        this.setItems(items);
    }

    onItemStatusChange(newItemData) {
        this.onItemStepChange(newItemData);
        Observer.notify(newItemData, 'plesk:taskUpdate');
        if (this.isCompletedItem(newItemData)) {
            Observer.notify(newItemData, 'plesk:taskComplete');
        }
    }

    onItemStepChange(newItemData) {
        Observer.notify(newItemData, 'plesk:taskStepUpdate');
    }

    setItems(items) {
        this._initItems(items);
        this._renderItems();
        this._updateProgressDialog();
    }

    fly(beginOffset, taskName, action) {
        const progressBarItemId = this.addPreparingItem(taskName);
        this._renderItems();
        // wait toast animation
        setTimeout(action, 300);
        return progressBarItemId;
    }

    progressDialog(task, { onHide, ...params } = {}) {
        const returnUrl = task && task.returnUrl;
        this._progressBarItem = task instanceof ProgressBar.Item ? task : createComponent(task);
        this.isOpenProgressDialog = true;

        if (!this._progressDialog) {
            this._progressDialog = document.createElement('div');
        }

        this.renderProgressDialog = () => {
            Plesk.require('app/progress-dialog', run => {
                if (!this._progressBarItem || !this._progressDialog) {
                    return;
                }

                run({
                    container: this._progressDialog,
                    isOpen: this.isOpenProgressDialog,
                    title: this._progressBarItem.getProgressTitle(),
                    steps: this._progressBarItem.getSteps(),
                    errors: this._progressBarItem.getVisibleErrors(),
                    onHide: () => {
                        this.isOpenProgressDialog = false;
                        this.renderProgressDialog();

                        if (onHide) {
                            onHide(this._progressBarItem);
                            return;
                        }

                        const redirectUrl = this._progressBarItem.isComplete()
                            ? this._progressBarItem._getConfigParam('redirect') || returnUrl
                            : returnUrl;
                        const doRedirect = () => {
                            if (redirectUrl) {
                                redirect(redirectUrl);
                            } else {
                                this.show();
                            }
                        };

                        if (this._progressBarItem.isCompleteSuccessfully() || this._progressBarItem.isCompleteWithWarning()) {
                            this._progressBarItem.remove().then(() => {
                                doRedirect();
                                this._progressBarItem = null;
                            });
                        } else {
                            doRedirect();
                        }
                    },
                    locale: Locale.getSection('components.tasks.common').messages,
                    ...params,
                });
            });
        };

        this.renderProgressDialog();
        this.update();
        this.hide();
    }

    hide() {
        this._hidden = true;
        this._renderItems();
    }

    show() {
        this._hidden = false;
        this._renderItems();
    }

    _updateProgressDialog() {
        if (!this._progressBarItem) {
            return;
        }

        const newItem = this.getItem(this._progressBarItem.getId());
        if (newItem) {
            this._progressBarItem = newItem;
        } else if (this._progressBarItem.getSteps().length) {
            // task was removed, show it as finished
            const finishedItem = createComponent(this.completeItem({
                ...this._progressBarItem.initialConfig,
                steps: this._progressBarItem.getSteps().map(step => this.completeItem(step)),
            }));
            finishedItem.setProgressBarElement(this._progressBarItem.getProgressBarElement());
            this._progressBarItem = finishedItem;
        }
        this.renderProgressDialog();
    }

    _renderItems() {
        super._renderItems();

        if (this._items.length) {
            this.setPeriodicalExecutor();
        }

        if (!this._loaded) {
            return;
        }

        Plesk.require('app/task-progress-bar', run => {
            run({
                container: this._componentElement,
                items: this._items,
                locale: Locale.getSection('components.tasks.common').messages,
                isOpen: !this._hidden,
            });
        });
    }

    _renderItem(item) {
        item.setProgressBarElement(this);
    }

    setPeriodicalExecutor() {
        if (!this.intervalUpdateTimer && this.hasStartedTasks()) {
            this.intervalUpdateTimer = setInterval(() => {
                this.update();
                if (!this.hasStartedTasks()) {
                    this.stopPeriodicalExecutor();
                }
            }, 5000);
        }
    }

    stopPeriodicalExecutor() {
        if (this.intervalUpdateTimer) {
            clearInterval(this.intervalUpdateTimer);
            this.intervalUpdateTimer = null;
        }
    }

    hasStartedTasks() {
        return this._items.some(item => item.isStarted());
    }

    isCompletedItem({ status }) {
        return [STATUS_DONE, STATUS_ERROR, STATUS_CANCELED].indexOf(status) !== -1;
    }

    completeItem(data) {
        return {
            ...data,
            status: this.isCompletedItem(data) ? data.status : STATUS_DONE,
        };
    }
}

ProgressBar.Item = class Item extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._id = this._getConfigParam('id', '');
        this._updated = new Date(this._getConfigParam('updated'));
        this._status = this._getConfigParam('status', '');
        this._errors = this._getConfigParam('errors', []);
        this._output = this._getConfigParam('output', []);
        this._isRefreshLinkEnabled = this._getConfigParam('isRefreshLinkEnabled', false)
            && this._getConfigParam('isRefreshAllowed', true);
        this._progressValue = this._getConfigParam('progressValue', 0);
        this._canCancel = this._getConfigParam('canCancel', true);
        this._referrer = this._getConfigParam('referrer', '');
        this._uniq = this._getConfigParam('uniq', undefined);
        this._progressBarElement = {};
    }

    _initComponentElement() {}

    getUpdated() {
        return this._updated;
    }

    getData() {
        return this._config;
    }

    getSteps() {
        return this._getConfigParam('steps', []);
    }

    getUniq() {
        return this._uniq;
    }

    setUniq(uniq) {
        this._uniq = uniq;
    }

    getKey() {
        return this._uniq || this._id;
    }

    setProgressBarElement(element) {
        this._progressBarElement = element;
    }

    getProgressBarElement() {
        return this._progressBarElement;
    }

    getProgressTitle() {
        return this._getConfigParam('progressTitleHtml', escapeHtml(this._getConfigParam('progressTitle', '')));
    }

    getProgressValue() {
        return this._progressValue;
    }

    getStatus() {
        return this._status;
    }

    setStatus(status) {
        this._status = status;
    }

    getProgressDialogLinkHandler() {
        if (!this.getSteps().length) {
            return undefined;
        }
        return e => {
            e.preventDefault();
            this.getProgressBarElement().progressDialog(this);
        };
    }

    isRefreshLinkEnabled() {
        return this._isRefreshLinkEnabled;
    }

    setRefreshLinkEnabled(flag) {
        this._isRefreshLinkEnabled = flag && this._isRefreshAllowed;
    }

    getRefreshLinkProps() {
        const _redirect = this._getConfigParam('redirect');
        if (_redirect) {
            const redirectUrl = _redirect.url || _redirect;
            return this.getProgressTitle().includes(redirectUrl) ? null : {
                title: _redirect.title,
                onClick: e => {
                    e.preventDefault();
                    redirect(_redirect);
                },
            };
        }

        if (this._isRefreshLinkEnabled && window.location.pathname === this._referrer) {
            return {
                onClick: e => {
                    e.preventDefault();
                    redirect(prepareUrl(window.location.pathname));
                },
            };
        }
    }

    hasErrors() {
        return this._errors.length > 0;
    }

    getErrors() {
        return this._errors;
    }

    getVisibleErrors() {
        const hideErrors = this._getConfigParam('hideErrors', false);

        if (hideErrors || !this.hasErrors()) {
            return [];
        }

        return this.getErrors();
    }

    getOutput() {
        return this._output.map(line => String(line).trim()).filter(Boolean);
    }

    isCompleteSuccessfully() {
        return STATUS_DONE === this._status && !this.hasErrors();
    }

    isComplete() {
        return [STATUS_DONE, STATUS_ERROR, STATUS_CANCELED].indexOf(this._status) !== -1;
    }

    isCompleteWithWarning() {
        return STATUS_DONE === this._status && this.hasErrors();
    }

    isStarted() {
        return STATUS_STARTED === this._status || STATUS_NOT_STARTED === this._status;
    }

    isPreparing() {
        return STATUS_PREPARING === this._status;
    }

    isFlying() {
        return STATUS_FLYING === this._status;
    }

    isProgressUndefined() {
        return this._progressValue === -1;
    }

    isCompleteWithError() {
        return STATUS_ERROR === this._status;
    }

    async remove() {
        try {
            await api.post(prepareUrl('/task/task-remove'), { ids: [this.getId()] });
            this.getProgressBarElement().removeItemsByIds([this.getId()]);
        } catch ({ message }) {
            addToast({ intent: 'danger', message });
        }
    }

    toToast() {
        const props = {
            key: this.getKey(),
        };
        if (this.isCompleteSuccessfully()) {
            return {
                ...props,
                intent: 'success',
                onClose: () => this.remove(),
                title: this.getProgressTitle(),
                output: this.getOutput(),
                refresh: this.getRefreshLinkProps(),
            };
        } else if (this.isCompleteWithWarning() || this.isCompleteWithError()) {
            return {
                ...props,
                intent: this.isCompleteWithWarning() ? 'warning' : 'danger',
                onClose: () => this.remove(),
                title: this.getProgressTitle(),
                errors: this.getVisibleErrors(),
                refresh: this.getRefreshLinkProps(),
            };
        } else if (this.isPreparing() || this.isFlying() || this.isProgressUndefined()) {
            return {
                ...props,
                title: this.isFlying() ? undefined : this.getProgressTitle(),
                progress: {
                    onClick: this.getProgressDialogLinkHandler(),
                },
            };
        }
        return {
            ...props,
            title: this.getProgressTitle(),
            progress: {
                value: this.getProgressValue(),
                onClick: this.getProgressDialogLinkHandler(),
            },
        };
    }
};
